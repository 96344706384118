.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Unauthorized {
  color: red;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.navbar {
  background-color: #282c34;
  padding: 1rem;
  height: auto;
  margin-bottom: 0.1rem;
  box-shadow: 0 (2 * 0.1rem);
}

.container-login {
  width: 100%;
  min-height: 100vh;
  background-color: linear-gradient(to right, #2BC3F3, #0096DA);
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.container-login .main {
  width: 380px;
  background: #fff;
  padding: 20px 40px;
  border-radius: 15px;
  color: #666;
  border: 1px solid #666;
}

.container-login button {
background-color: orange;
border: none;
color: white;
padding: 15px 32px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 16px;
}
